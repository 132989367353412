import * as React from "react"
import { Link, graphql } from 'gatsby'

import Seo from "../components/seo"
import Layout from "../components/layout"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash'

const seoTitle = "広島オンライン酒場｜ TANOSHIGE【たのしげ】"
const seoDescription = "今の広島の「食べてみたい」「行ってみたい」「教えたい」を楽しげに語り合うオンライン酒場です。毎回テーマを決めて広島の魅力を発信しています。　第２・４金曜日の夜８時〜Youtubeにて生配信中！"

const Article = (nodes) => {
  return (
    nodes.map((node) => {
      const image = getImage(node.featuredImage?.node?.localFile)

      return (
        <article>
          <Link to={node.uri}>
            <div className="article-thumbnail"><GatsbyImage image={image} alt={node.title} /></div>
            <div className="article-ttl">
              <p>{node.date}</p>
              <h1>{node.title}</h1>
            </div>
          </Link>
        </article>
      )
    })
  )
}

const OnlinebarPage = ({ data }) => {
  let posts = []

  data.allWpOnlinebar.edges.forEach(({ node }) => {
    posts.push(node)
  })

  let postsGourmet = _.filter(posts, (o) => { return o.onlinebarCategories?.nodes[0]?.slug === 'gourmet' })
  let postsSpot = _.filter(posts, (o) => { return o.onlinebarCategories?.nodes[0]?.slug === 'spot' })
  let postsEtc = _.filter(posts, (o) => { return o.onlinebarCategories?.nodes[0]?.slug === 'etc' })

  return (
    <Layout isHomePage={true} headerClass="bg-black" isLogoOneWhite={true} isLogoTwoWhite={true}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="online-bar-list">
        <section id="online-kv-box">
          <div id="online-ttl">
            <h2>
              <StaticImage src="../images/online-ttl.png" alt="広島オンライン酒場" />
              <StaticImage id="online-stamp" src="../images/online-stamp.png" alt="第２・第４金曜 20:00〜" />
            </h2>
            <h3><StaticImage src="../images/online-subttl.png" alt="Visible Radio Connected TV" /></h3>
          </div>
        </section>
        <Tabs>
          <TabList className="swich-tab inner">
            <Tab>記事一覧</Tab>
            <Tab>グルメ</Tab>
            <Tab>スポット</Tab>
            <Tab>その他</Tab>
          </TabList>
          <TabPanel className="article-list inner">
            {Article(posts)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsGourmet)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsSpot)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsEtc)}
          </TabPanel>
        </Tabs>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpOnlinebar(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          date(formatString: "YYYY. MM. DD")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO]
                  )
                }
              }
            }
          }
          onlinebarCategories {
            nodes {
              id
              slug
            }
          }
        }
      }
    }
  }
`

export default OnlinebarPage
